import { useMemo, useState } from "react";
import "./App.css";

function useC() {
  const [c, setC] = useState("");
  const vv = useMemo(() => {
    const valids = (c || "")
      .split(/[\r\n]/g)
      .map((v) => v.trim())
      .filter(Boolean);
    const uniqs = new Set(valids);
    return { validCount: valids.length, uniqs };
  }, [c]);
  return {
    inputProps: {
      value: c,
      onChange: (e) => setC(e.currentTarget.value),
    },
    ...vv,
  };
}

function App() {
  const c1 = useC();
  const c2 = useC();
  const r = useMemo(() => {
    const [small, big] = c1.uniqs.size < c2.uniqs.size ? [c1.uniqs, c2.uniqs] : [c2.uniqs, c1.uniqs];
    const result = [];
    for (let row of small) {
      if (big.has(row)) {
        result.push(row);
      }
    }
    return { preview: result.join("\n"), count: result.length };
  }, [c1.uniqs, c2.uniqs]);
  return (
    <div className="cont">
      {[c1, c2].map((c, index) => {
        const ind = index + 1;
        return (
          <div className={"c c-" + ind} key={ind}>
            <header>
              去重/有效：{c.uniqs.size}/{c.validCount}
            </header>
            <main>
              <textarea {...c.inputProps} placeholder={`输入第${ind}个列表`}></textarea>
            </main>
          </div>
        );
      })}
      <div className="r">
        <header>相同项：{r.count}</header>
        <main>
          <textarea value={r.preview} readOnly placeholder="两个列表共有项"></textarea>
        </main>
      </div>
    </div>
  );
}

export default App;
